import React, { useContext } from "react";
import { Row, Col, DatePicker } from "antd";
import { LanguageContext } from "../../../../../context/Language";
import Date from "../../../../Form/Date";
import Input from "../../../../Form/Input";
import Select from "../../../../Form/Select";
import moment from "moment";

const AdvanceInfo = ({ state, setInput, errors}) => {

  const { dictionary, languagesList } = useContext(LanguageContext);

  const handleSelect = (e, name) => {
    setInput({
      ...state,
      [name]: e,
    });
  };

  const changeHandler = (e) => {
    setInput({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const changeHandlerYear = (e) => {
    setInput({
      ...state,
      recordYear: moment(e).format("YYYY"),
    });
  };

  const onChangepreOrderDate = (date, dateString) => {
    setInput({
      ...state,
      preOrder: dateString,
    });
  };

  const disabledDate = (current) => {
    return current && current.isAfter(state.relDate, "day");
  };

  return (
    <div className="forms">
      <Row className="gutter-row">
        <Col xs={24} sm={24} md={12} lg={12} className="col1">
          <label className={"allWidth"}>
            {dictionary.PreOrderDate}*
          </label>
          <DatePicker
            value={state.preOrder ? moment(state.preOrder,  "YYYY-MM-DD") : null}
            onChange={onChangepreOrderDate}
            disabledDate={disabledDate}
            placeholder={dictionary.selectDate}
            className={errors.preOrderErr ? "inputErr " : ""}
            inputReadOnly={true}
          />
          <Date
            picker="year"
            name="recordYear"
            value={state.recordYear}
            onChange={changeHandlerYear}
            label={dictionary.recordingYear}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} className="col2">
          <Select
            name="metadata"
            required={true}
            value={state.metadata}
            options={languagesList || []}
            error={errors.metadataErr}
            placeholder={dictionary.select}
            label={dictionary.metadataLanguage}
            onChange={(e) => handleSelect(e, "metadata")}
            className={
              (errors.metadataErr ? "countErr " : "") + " selector-general"
            }
          />
          <Input
            name="recordLoc"
            value={state.recordLoc}
            onChange={changeHandler}
            label={dictionary.recordingLocation}
          />
        </Col>
      </Row>
    </div>
  );
};

export default AdvanceInfo;
