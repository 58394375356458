import React, { useContext, useState } from "react";
import { Input, notification, Typography } from "antd";
import { LanguageContext } from "../../../../../context/Language";
import Modal from "../../../../Modal";
import Button from "../../../../Buttons/GeneralButton";
import AdminCalls from "../../../../../classes/admin/admin";
import RejectionTimeLine from "../../../../TimeLine/RejectionTimeLine";
import "./ReviewNoteModal.css";

const ReviewNoteModal = ({
  onConfirm,
  onCancel,
  isModalVisible,
  reviewNotes = [],
  releaseTitle = "",
  releaseId,
}) => {
  const { dictionary } = useContext(LanguageContext);
  const [newNote, setNewNote] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const sendHandler = () => {
    setIsLoading(true);
    const noteToSend = newNote.length > 0 ? { reason: newNote } : null;
    AdminCalls.rejectRelease(releaseId, noteToSend)
      .then((rejectStatus) => {
        if (rejectStatus.status === 200) {
          let succesRel = dictionary.releaseRejected.replace(
            "--name--",
            releaseTitle
          );
          notification.success({
            message: succesRel,
            duration: 5,
            placement: "bottomRight",
          });
        }
        onConfirm();
      })
      .catch(() => {
        notification.warning({
          message: dictionary.stripeError,
          duration: 5,
          placement: "bottomRight",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Modal
      idModal="release-reject-modal"
      handleCancel={() => (!isLoading ? onCancel() : null)}
      isModalVisible={isModalVisible}
      title={
        <div className="modal-title">
          {dictionary.rejectSendToClient}
          <br />
          <Typography.Title className="mt-1" level={5} type="secondary">
            <em>{releaseTitle}</em>
          </Typography.Title>
        </div>
      }
      body={
        <div id="release-reject-modal-content">
          <RejectionTimeLine rejections={reviewNotes} />
          <label>{dictionary.addNote}</label>
          <Input.TextArea
            rows={4}
            value={newNote}
            onChange={(e) => {
              setNewNote(e.target.value);
            }}
          />
        </div>
      }
      footer={
        <div id="release-reject-modal-actions" className="modalAdd-btns">
          <Button
            key="cancel"
            className="back-btn"
            text={dictionary.cancel}
            onClick={onCancel}
          />
          <Button
            size="ms"
            className="reject"
            onClick={() => sendHandler()}
            text={
              newNote.length > 0 ? dictionary.rejectAndNote : dictionary.reject
            }
            disabled={isLoading}
          />
        </div>
      }
    />
  );
};

export default ReviewNoteModal;
