import React, { useState, useContext, useEffect, useRef } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { notification } from 'antd';
import { LanguageContext } from "../../context/Language";
import Visitor from "../../classes/visitor/visitor";
import StripeCalls from "../../classes/calls/payment/stripe";
import SecureLS from "secure-ls";
import Cookies from "js-cookie";
import Terms from "../../components/Register/Terms";
import Progress from "../../components/Steps/WizardBar";
import Create from "../../components/Register/Create";
import Details from "../../components/Register/Details";
import Pricing from "../../components/Register/Pricing";
import Code from "../../components/Register/Code";
import Captcha from "../../components/Captcha";
import "./register.css";

const ls = new SecureLS({ encodingType: "base64", isCompression: false });

const Register = () => {
  const { dictionary, locale } = useContext(LanguageContext);
  const notificationShown = useRef(false);
  const [hasNotification, setHasNotification] = useState(false);
  const [section, setSection] = useState(0);
  const [validated, setValidated] = useState(false);
  const [showRedirectionAdvise, setShowRedirectionAdvise] = useState(false);
  const [state, setInput] = useState({
    name: "",
    last: "",
    email: "",
    externalId: null,
    platformExternal: null,
    type: 0,
    passwd: "",
    pwdCfm: "",
    company: "",
    address: "",
    city: "",
    zipCode: "",
    state: "",
    country: 0,
    website: "",
    phone: "",
    lada: "US",
    phoneCode: "1",
    plan: null,
    terms: false,
    verified: false,
  });

  const [token, setToken] = useState("");
  const [expiredCaptcha, setExpiredCaptcha] = useState(false);
  const [resentCaptcha, setResentCaptcha] = useState(false);

  useEffect(() => {
    if (!notificationShown.current) {
      setHasNotification(true);
      const notificationKey = 'beta-notification';
      notification.warning({
        key: notificationKey,
        message: dictionary.beta.title,
        description: (
          <div>
            {dictionary.beta.description}{' '}
            <a href="https://dashboard.priamdigital.com" target="_blank" rel="noopener noreferrer">
              {dictionary.beta.liveUrl}
            </a>
          </div>
        ),
        duration: 0,
        placement: 'top',
        onClose: () => setHasNotification(false),
        className: 'beta-notification'
      });
      notificationShown.current = true;
    }
    const key = ls.get("state");
    let userCookie = Cookies.get("userInfo");
    let userInfo = {};
    if (userCookie) userInfo = JSON.parse(userCookie.substring(2));

    if (key) {
      key.name = userInfo.name || key.name;
      key.last = userInfo.last || key.last;
      key.email = userInfo.email || key.email;
      key.externalId = userInfo.id || key.externalId;
      key.platformExternal = userInfo.platform || key.platformExternal;
      key.verified = userInfo.verified || key.verified;
      key.phoneCode = userInfo.phoneCode || key.phoneCode;
      setValidated(userInfo.verified || key.verified);
      setInput({
        ...state,
        ...key,
      });
    } else {
      state.name = userInfo.name || state.name;
      state.last = userInfo.last || state.last;
      state.email = userInfo.email || state.email;
      state.externalId = userInfo.id || state.externalId;
      state.platformExternal = userInfo.platform || state.platformExternal;
      state.verified = userInfo.verified || state.verified;
      state.phoneCode = userInfo.phoneCode || state.phoneCode;
      setValidated(userInfo.verified || state.verified);

      setInput(state);
    }
    // eslint-disable-next-line
  }, []);

  const goToPay = (retry = 3) => {
    setShowRedirectionAdvise(true);
    setTimeout(() => {
      StripeCalls.createCheckoutSession().then((url) => {
        if (url) window.location.href = url;
        else {
          if (retry > 0) goToPay(retry - 1);
        }
      });
    }, 3000);
  };

  const signUp = () => {
    Visitor.signup({ ...state }, token, locale).then(async (signup) => {
      if (signup.status === 200 && signup.body.user) {
        if(ls.get("inviteToken")){
          let inviteToken = ls.get("inviteToken");
          await Visitor.deleteInviteToken(inviteToken).then(() => {
            return;
          });
        }
        ls.removeAll();
        if (state.plan === "core") goToPay();
        else window.location.href = "/thanks";
      } else if (
        signup.status === 400 &&
        signup.body.errors &&
        signup.body.errors.length &&
        signup.body.errors.findIndex(
          (err) => err.message === "expiredCaptcha"
        ) >= 0
      ) {
        setResentCaptcha(false);
        setExpiredCaptcha(true);
      } else {
        window.location.href = "/signupError";
      }
    });
  };

  const setKeys = (info) => {
    ls.set("state", info);
  };

  const changeSection = (n) => {
    setSection(n);
    window.scrollTo(0, 0);
  };

  const drawSteps = () => {
    if (section === 0)
      return (
        <Create
          state={state}
          setKeys={setKeys}
          setInput={setInput}
          changeSection={changeSection}
          setToken={setToken}
        />
      );

    if (section === 1)
      return (
        <Details
          state={state}
          setKeys={setKeys}
          setInput={setInput}
          changeSection={changeSection}
        />
      );

    if (section === 2)
      return (
        <Terms
          state={state}
          setKeys={setKeys}
          setInput={setInput}
          changeSection={changeSection}
          token={token}
        />
      );

    return (
      <Pricing
        state={state}
        setKeys={setKeys}
        setInput={setInput}
        changeSection={changeSection}
        signUp={signUp}
      />
    );
  };

  const drawCode = () => {
    return (
      <Code
        state={state}
        setKeys={setKeys}
        setInput={setInput}
        setValidated={setValidated}
        setToken={setToken}
      />
    );
  };

  const drawForm = () => {
    return (
      <React.Fragment>
        {drawSteps()}
        <GoogleReCaptchaProvider
          reCaptchaKey="6Lf1tDseAAAAAN6_aJeASuEybHK-c8fTsMEjBvLL"
          language="es-419"
        >
          <Captcha
            setToken={setToken}
            expired={expiredCaptcha}
            resent={resentCaptcha}
            setResent={setResentCaptcha}
          />
        </GoogleReCaptchaProvider>
      </React.Fragment>
    );
  };

  const content = showRedirectionAdvise ? (
    <h3 className="p-4 center">
      {dictionary.redirectInN.replaceAll(/{{N}}/g, "3")}
    </h3>
  ) : validated ? (
    drawForm()
  ) : (
    drawCode()
  );

  return (
    <React.Fragment>
      <div className={`container-box wizard ${hasNotification ? 'has-notification' : ''}`}>
        <div className="wizard-box">
          <Progress total={5} step={validated ? section + 1 : 0} />
          <h1>{dictionary.welcomeTo}</h1>
          <h3>{dictionary.welcomeToDesc}</h3>
          {content}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Register;
