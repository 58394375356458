import React, { useContext, useEffect, useState } from "react";
import { Card, Empty, notification } from "antd";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../../../context/Language";
import Bluebird from "bluebird";
import DeleteDraft from "./DeleteDraft";
import ReleaseList from "./ReleaseList";
import PlusIcon from "../../../Icon/PlusIcon";
import Button from "../../../Buttons/GeneralButton";
import Release from "../../../../classes/release/release.jsx";

const ReleaseCard = ({
  releaseList,
  listName,
  released,
  plus,
  changeItem,
  getReleases,
  canDelete = false,
  section,
}) => {
  const { dictionary } = useContext(LanguageContext);
  const [releases, setReleases] = useState([]);
  const [deleteTitle, deleteRelTitle] = useState(false);
  const [deleteVisible, deleteRelVisible] = useState(false);

  useEffect(() => {
    setListReleases();
    // eslint-disable-next-line
  }, [releaseList]);

  const extractArtist = async (main, featured) => {
    if (!main.length) {
      return "";
    }
    let artistName = main[0].name;
    let featuredArtists = "";
    await featured.forEach(async (el, index) => {
      featuredArtists += el.name;
      if (index + 1 < featured.length) {
        featuredArtists += " & ";
      }
    });
    return featuredArtists.length
      ? artistName + " ft. " + featuredArtists
      : artistName;
  };

  const setListReleases = async () => {
    let cdArr = [];
    await Bluebird.map(releaseList, async (el, index) => {
      let artistName = await extractArtist(el.main_artist, el.featured_artist);
      let image = el.image_cover
        ? el._id + "/" + el.image_cover.filename
        : null;
      let imageResized = null;
      if (image) {
        let checksum = el.image_cover?.checksum || "";
        const getResized = image.replace(
          /.jpg|.png|.jpeg|.heic/gi,
          function () {
            return "250x250.webp?v=" + checksum;
          }
        );
        imageResized = getResized;
      }
      cdArr.push(
        <ReleaseList
          key={index}
          img={image}
          section={section}
          idRelease={el._id}
          released={released}
          canDelete={canDelete}
          changeItem={changeItem}
          imgResized={imageResized}
          deleteRelease={deleteRelease}
          showDeleteDraft={showDeleteDraft}
          title={!el.title ? dictionary.noTitle : el.title}
          artist={artistName === "" ? dictionary.noArtist : artistName}
          myClass={listName !== dictionary.recentReleases ? "add-space" : ""}
          rejections={el.rejections || []}
        />
      );
    }).then(() => {
      setReleases(cdArr);
    });
  };

  const deleteCancel = () => {
    deleteRelVisible(false);
  };

  const showDeleteDraft = (id, title) => {
    deleteRelVisible(id);
    deleteRelTitle(title);
  };

  const deleteRelease = (id) => {
    try {
      Release.updateDraftStatus(id, false).then((res) => {
        if (res.error) {
          notification.error({
            message: dictionary.errorGeneral,
            duration: 3,
            placement: "topRight",
          });
        } else {
          deleteRelVisible(false);
          notification.success({
            message: dictionary.delRelSuccess,
            duration: 3,
            placement: "topRight",
          });
        }
      });
      getReleases();
    } catch (e) {
      console.log(e);
    }
  };

  const plusIcon =
    plus === true ? (
      <Link to={"/newRelease"}>
        <PlusIcon className="add-rel" />{" "}
      </Link>
    ) : null;

  const viewAllBtn = <Button text={dictionary.viewAll} className="view-all" />;

  return (
    <div className="general-card">
      <Card>
        <p>{listName}</p>
        <Link onClick={() => changeItem("/release")} to={"/release#" + section}>
          {releases.length ? viewAllBtn : null}
        </Link>
        <div className="list-rel">
          <ul
            className={
              (!releases.length ? "empty-stylecard" : "") + " list-cd dash_home"
            }
          >
            {plus ? (
              <li className="cd-data" onClick={() => changeItem("/newRelease")}>
                {plusIcon}
              </li>
            ) : null}
            {!releases.length && (
              <li className="cd-data">
                <Empty
                  className="empty-rel"
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={
                    <div className={"no-release-description"}>
                      {dictionary.noReleases}
                    </div>
                  }
                />
              </li>
            )}
            {releases}
          </ul>
        </div>
      </Card>
      {deleteVisible ? (
        <DeleteDraft
          deleteTitle={deleteTitle}
          handleCancel={deleteCancel}
          deleteRelease={deleteRelease}
          deleteVisible={deleteVisible}
        />
      ) : null}
    </div>
  );
};

export default ReleaseCard;
