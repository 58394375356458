import { Row, Col, Typography } from "antd";
import React, { useContext, useEffect, useMemo, useState, useRef } from "react";
import { LanguageContext } from "../../../../context/Language";
import { ArtistContext } from "../../../../context/Artist";
import { AppContext } from "../../../../context/App";
import Contributors from "../Contributors";
import CoverHeader from "./CoverHeader";
import UPCSetter from "./UPCSetter";
import "./header.css";

const Header = (props) => {
  const {
    isLoading,
    dataToUpdate,
    dispatch,
    isEditMode,
    label_id: labelId,
    title,
    upc,
    releaseId,
    loadRelease,
    isUpdateUpc,
    enableEdit,
    setShowAddUpcIsrcModal,
  } = props;

  const trackData = {
    artists: props.artists,
    writers: props.writers,
    producers: props.producers,
  };

  const { invertedAudiosaladStatus } = useContext(AppContext);
  const { dictionary } = useContext(LanguageContext);
  const {
    rolesByType,
    artistRoleOptions,
    writerRoleOptions,
    additionalRoleOptions,
    artistsByLabelId,
    invertedRoles,
  } = useContext(ArtistContext);

  const [artists, setArtists] = useState({});
  const [writers, setWriters] = useState({});
  const [additional, setAdditional] = useState({});
  // This come back to the reducer, cuz we need to update the data from server
  const [originalContributors, setOriginalContributors] = useState({
    artists: [],
    writers: [],
    producers: [],
  });

  const labelArtists = useMemo(() => {
    if (labelId) {
      return artistsByLabelId[labelId] || [];
    }
    return [];
  }, [labelId, artistsByLabelId]);

  const contributorOptions = useMemo(() => {
    return labelArtists.map((item) => {
      return { label: item.name, value: item._id };
    });
  }, [labelArtists]);

  /**
   * this effect is duplicated in the TrakContributors.jsx
   * Sorry this dont work, cuz contributors component dont take the props
   const {
    contributorOptions,
    artists,
    writers,
    additional,
    labelArtists,
    originalContributors,
  } = useContributorState({ trackData, dataToUpdate, labelId });
   */
  useEffect(() => {
    if (Object.keys(trackData).length === 0) return;

    // We need to reconcile the data from the server with the data from the user
    // The item.origin is very important, cuz its used in the reducer with originalContributors
    const result = [
      ...(dataToUpdate?.artists || trackData?.artists || []).map((item) => {
        item.origin = "artists";
        return item;
      }),
      ...(dataToUpdate?.writers || trackData?.writers || []).map((item) => {
        item.origin = "writers";
        return item;
      }),
      ...(dataToUpdate?.producers || trackData?.producers || []).map((item) => {
        item.origin = "producers";
        return item;
      }),
    ];

    if (result.length === 0) return;

    // This was changed to objects cuz we need to use the contributorId as key
    // from the original data, so the contributorRole is the server structure
    // {contributorId: [contributorRole, contributorRole, ...]]}
    const artistList = {};
    const writerList = {};
    const additionalList = {};

    // For meet the requirements we need to restucture the data,
    // cuz the roles are diferent between the server and the presentation view,
    // (change the server data implies several changes in both sides)
    // you can see the ArtistContext.js file for more details about the roles
    for (const contributor of result) {
      let added = false;

      if (rolesByType["artist"]?.includes(contributor.role)) {
        if (Object.hasOwnProperty.call(artistList, contributor.artist_id)) {
          artistList[contributor.artist_id].push(contributor);
        } else {
          artistList[contributor.artist_id] = [contributor];
        }
        added = true;
      }

      if (rolesByType["writer"]?.includes(contributor.role)) {
        if (Object.hasOwnProperty.call(writerList, contributor.artist_id)) {
          writerList[contributor.artist_id].push(contributor);
        } else {
          writerList[contributor.artist_id] = [contributor];
        }
        added = true;
      }

      if (rolesByType["participant"]?.includes(contributor.role)) {
        if (Object.hasOwnProperty.call(additionalList, contributor.artist_id)) {
          additionalList[contributor.artist_id].push(contributor);
        } else {
          additionalList[contributor.artist_id] = [contributor];
        }
        added = true;
      }

      if (!added) {
        if (Object.hasOwnProperty.call(additionalList, contributor.artist_id))
          additionalList[contributor.artist_id].push(contributor);
        else additionalList[contributor.artist_id] = [contributor];
      }
    }
    setArtists(artistList);
    setWriters(writerList);
    setAdditional(additionalList);
    setOriginalContributors({
      artists: trackData.artists,
      writers: trackData.writers,
      producers: trackData.producers,
    });
  }, [
    JSON.stringify(trackData.artists),
    JSON.stringify(trackData.writers),
    JSON.stringify(trackData.producers),
    JSON.stringify(dataToUpdate.artists),
    JSON.stringify(dataToUpdate.writers),
    JSON.stringify(dataToUpdate.producers),
  ]);

  const titleRef = useRef(null);

  return (
    <div className="header-release">
      <Row gutter={[16, 8]}>
        <Col xs={24} lg={12}>
          <Row gutter={[16, 8]}>
            <Col xs={24} lg={10} className="cover-header-container">
              <CoverHeader
                releaseId={releaseId}
                asset={props.asset || []}
                s3_url={props.s3_url || ""}
                dispatch={dispatch}
                isEditMode={isEditMode}
              />
            </Col>
            <Col xs={24} lg={14}>
              <Row gutter={[16, 8]} className="bg-title">
                <Col flex="auto" className="title-wraper">
                  <Typography.Title
                    ref={titleRef}
                    onBlur={() => {
                      dispatch({
                        type: "simpleChange",
                        payload: {
                          name: "title",
                          original: props.title,
                          value: titleRef.current.textContent,
                        },
                      });
                    }}
                    contentEditable={enableEdit}
                    level={2}
                    id="title-h1-header"
                  >
                    {(dataToUpdate?.title ?? title) || dictionary.noTitle}
                  </Typography.Title>
                </Col>
              </Row>
              <Row gutter={[16, 8]} align="middle" className="mt-2">
                <Col flex="auto">
                  <UPCSetter
                    releaseId={releaseId}
                    upc={upc}
                    isUpdateUpc={isUpdateUpc}
                    disabled={!enableEdit}
                    loadRelease={loadRelease}
                    setShowAddUpcIsrcModal={setShowAddUpcIsrcModal}
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col span={24}>
                  <div className="stats">
                    <div className="mays">{dictionary.status}</div>:{" "}
                    {props.audiosalad_status === "In Review" ? dictionary.sendingToStores :
                    dictionary[
                      invertedAudiosaladStatus[props.audiosalad_status]
                    ] || props.audiosalad_status}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col xs={24} lg={12}>
          <Typography.Title level={4}>
            {dictionary.artist}
          </Typography.Title>
          <Contributors
            isLoading={isLoading}
            labelArtists={labelArtists}
            isEditMode={enableEdit}
            textAction={dictionary.addArtist}
            originalContributors={originalContributors}
            addContributorKey="artists"
            contributors={artists}
            contributorOptions={contributorOptions}
            roleOptions={artistRoleOptions}
            invertedRoles={invertedRoles}
            withRoles={false}
            withFeatured={true}
            disabled={!enableEdit}
            onChange={dispatch}
          />
        </Col>
      </Row>

      <Row gutter={[16, 8]} className="mt-3 contributors-edit-view">
        <Col xs={24} lg={12}>
          <Typography.Title level={4}>
            {dictionary.writersAndPublishers}
          </Typography.Title>
          <Contributors
            isLoading={isLoading}
            labelArtists={labelArtists}
            isEditMode={enableEdit}
            textAction={dictionary.addWriter}
            originalContributors={originalContributors}
            addContributorKey="writers"
            contributors={writers}
            contributorOptions={contributorOptions}
            roleOptions={writerRoleOptions}
            invertedRoles={invertedRoles}
            disabled={!enableEdit}
            onChange={dispatch}
            multiple
          />
        </Col>
        <Col xs={24} lg={12}>
          <Typography.Title level={4}>
            {dictionary.additionalContributors}
          </Typography.Title>
          <Contributors
            isLoading={isLoading}
            labelArtists={labelArtists}
            isEditMode={enableEdit}
            textAction={dictionary.addContributor}
            originalContributors={originalContributors}
            addContributorKey="producers"
            contributors={additional}
            contributorOptions={contributorOptions}
            roleOptions={additionalRoleOptions}
            invertedRoles={invertedRoles}
            disabled={!enableEdit}
            onChange={dispatch}
            multiple
          />
        </Col>
      </Row>
    </div>
  );
};

export default Header;
