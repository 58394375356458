import {
  PlayCircleFilled,
  PauseCircleFilled,
  LoadingOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Col, Row, Upload, Typography, Popover, notification } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import UploadIcon from "../Icon/UploadIcon";
import createObjectURLWorker from "../../workers/createObjectURLWorker";
import WorkerBuilder from "../../workers/builder";
import { LanguageContext } from "../../context/Language";
import { getAudioChannels, isAtmosAudio } from "../../utils/audioUtils";

// Singleton para manejar el audio global
const AudioManager = {
  currentAudio: null,
  currentId: null,
  setCurrentAudio(audio, id) {
    if (this.currentAudio && this.currentAudio !== audio) {
      this.currentAudio.pause();
    }
    this.currentAudio = audio;
    this.currentId = id;
  },
  clearCurrent(audio) {
    if (this.currentAudio === audio) {
      this.currentAudio = null;
      this.currentId = null;
    }
  },
  reset() {
    if (this.currentAudio) {
      try {
        this.currentAudio.pause();
        this.currentAudio = null;
        this.currentId = null;
      } catch (e) {
        // Silenciar error si el elemento ya no existe
      }
    }
  },
};

const AudioPlayer = ({
  replaceMediaChangeHandler = null,
  dataFromFile = null,
  dataFromURI = null,
  showAsIconButton = false,
  title = "Audio",
  iconWidth = 30,
  iconHeight = 30,
  audioId = null,
}) => {
  const audioRef = useRef();
  const { dictionary } = useContext(LanguageContext);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAtmos, setIsAtmos] = useState(false);

  useEffect(() => {
    const audioElement = audioRef.current;

    const handlePlay = () => {
      AudioManager.setCurrentAudio(audioElement, audioId);
      setIsPlaying(true);
    };

    const handlePause = () => {
      AudioManager.clearCurrent(audioElement);
      setIsPlaying(false);
    };

    const handleEnded = () => {
      AudioManager.clearCurrent(audioElement);
      setIsPlaying(false);
    };

    audioElement.addEventListener("play", handlePlay);
    audioElement.addEventListener("pause", handlePause);
    audioElement.addEventListener("ended", handleEnded);

    return () => {
      AudioManager.clearCurrent(audioElement);
      audioElement.removeEventListener("play", handlePlay);
      audioElement.removeEventListener("pause", handlePause);
      audioElement.removeEventListener("ended", handleEnded);
    };
  }, [audioId]);

  const setAudioSource = (
    audioUrl,
    fileType = "audio/x-wav",
    fromRetry = false
  ) => {
    const audioTag = audioRef.current;
    if (audioTag) {
      while (audioTag.firstChild) audioTag.removeChild(audioTag.firstChild);

      let audioSrc = audioUrl;
      let mimeSrc = fileType;
      // first try
      if (!fromRetry && fileType !== "audio/mpeg") {
        audioSrc = audioUrl
          .replace(".com/", ".com/public/")
          .replace("." + audioUrl.split(".").pop(), "_mp3.mp3");
        mimeSrc = "audio/mpeg";
      }

      const source = document.createElement("source");
      source.setAttribute("type", mimeSrc);
      source.setAttribute("src", audioSrc);
      source.onerror = () => {
        // Retry with the original source, last param to avoid infinite loop
        if (!fromRetry) setAudioSource(audioUrl, fileType, true);
        else setIsLoading(false);
      };

      audioTag.append(source);
      audioTag.preload = "auto";
      audioTag.volume = 0.5;

      setIsLoading(true);
      audioTag.load();
      audioTag.oncanplaythrough = () => setIsLoading(false);
    }
  };

  useEffect(() => {
    if (dataFromFile && dataFromFile.originFileObj) {
      setIsLoading(true);

      getAudioChannels(dataFromFile.originFileObj).then((channels) => {
        if (isAtmosAudio(channels)) {
          setIsAtmos(true);
          setIsLoading(false);
          return;
        }

        setIsAtmos(false);
        const AudioWorker = new WorkerBuilder(createObjectURLWorker);
        AudioWorker.onmessage = function (event) {
          setAudioSource(event.data.objectURL, dataFromFile?.type);
        };

        AudioWorker.postMessage(dataFromFile.originFileObj);
        return () => AudioWorker.terminate();
      });
    }
  }, [dataFromFile]);

  useEffect(() => {
    if (dataFromURI) {
      setIsLoading(true);
      const { url, filename, format } = dataFromURI;
      const audio = url + "/" + filename;
      setAudioSource(audio, format === "wav" ? "audio/x-wav" : "audio/flac");
    }
  }, [dataFromURI]);

  const togglePlay = () => {
    if (isLoading) return;

    const audioElement = audioRef.current;
    if (isPlaying) {
      audioElement.pause();
    } else {
      audioElement.play().catch((error) => {
        console.error("Error playing audio:", error);
        setIsPlaying(false);

        notification.warning({
          message: dictionary.atmosTrack,
          description: dictionary.audioPlaybackError,
          placement: "bottomRight",
          duration: 6,
          style: {
            width: 400,
          },
        });
      });
    }
  };

  useEffect(() => {
    return () => {
      AudioManager.reset();
    };
  }, []);

  if (showAsIconButton)
    return (
      <div>
        {isLoading ? (
          <LoadingOutlined className="icon-loading" />
        ) : isAtmos ? (
          <Popover
            content={dictionary.atmosWarning}
            title={dictionary.atmosTrack}
            placement="top"
          >
            <WarningOutlined style={{ color: "#faad14" }} />
          </Popover>
        ) : isPlaying ? (
          <PauseCircleFilled
            width={iconWidth}
            height={iconHeight}
            className="icon-pause-track"
            onClick={togglePlay}
          />
        ) : (
          <PlayCircleFilled
            width={iconWidth}
            height={iconHeight}
            className="icon-play-track"
            onClick={togglePlay}
          />
        )}
        {!isAtmos && (
          <audio
            preload="auto"
            ref={audioRef}
            controls="nodownload"
            className="hidden"
            hidden
          >
            <source />
            Your browser does not support the <code>audio</code> element.
          </audio>
        )}
      </div>
    );

  return (
    <>
      {title !== "" && (
        <Row>
          <Col>
            <Typography.Title level={3}>Audio</Typography.Title>
          </Col>
        </Row>
      )}
      <Row align="middle" gutter={[16, 16]}>
        <Col
          xs={24}
          sm={replaceMediaChangeHandler ? 14 : 24}
          md={replaceMediaChangeHandler ? 16 : 24}
        >
          <audio
            preload="auto"
            ref={audioRef}
            controls="nodownload"
            className="audio-preview"
          >
            <source src="" />
            Your browser does not support the <code>audio</code> element.
          </audio>
        </Col>
        {replaceMediaChangeHandler ? (
          <Col xs={24} sm={10} md={8}>
            <Upload
              customRequest={() => {}}
              name="avatar"
              accept="audio/x-wav, audio/wav, audio/x-flac, audio/flac"
              showUploadList={false}
              onChange={replaceMediaChangeHandler}
              listType="picture-card"
              className={"track-media-uploader ml-1"}
              maxCount={1}
            >
              <UploadIcon className="upload-icon" /> {dictionary.replaceMedia}
            </Upload>
          </Col>
        ) : null}
      </Row>
    </>
  );
};

export default AudioPlayer;
