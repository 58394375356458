import React, { useEffect, useState, useContext, useRef } from "react";
import "./home.css";
import Logo from "../../logo.svg";
import SignUp from "../../components/SignUp";
import Login from "../../components/SignIn";
import { LanguageContext } from "../../context/Language";
import { notification } from 'antd';

const Home = () => {
  const { dictionary } = useContext(LanguageContext);
  const [email, setEmail] = useState("");
  const [login, goLogin] = useState(false);
  const [externalError, setExternalError] = useState(false);
  const notificationShown = useRef(false);
  const [hasNotification, setHasNotification] = useState(false);

  useEffect(() => {
    if (window.location.hash.includes("#googlealreadyRegistered")) {
      setExternalError(
        dictionary.alreadyExternalEmail.replace("--metodo--", "Google")
      );
    }
    if (window.location.hash.includes("#facebookalreadyRegistered")) {
      setExternalError(
        dictionary.alreadyExternalEmail.replace("--metodo--", "Facebook")
      );
    }
    if (window.location.hash === "#googleinvalidLogin") {
      setExternalError(
        dictionary.invalidExternalEmail.replace("--metodo--", "Google")
      );
    }
    if (window.location.hash === "#facebookinvalidLogin") {
      setExternalError(
        dictionary.invalidExternalEmail.replace("--metodo--", "Facebook")
      );
    }
    if (
      window.location.hash.includes("#facebookinactiveAccount") ||
      window.location.hash.includes("#googleinactiveAccount")
    ) {
      setExternalError(dictionary.inactiveAccountLogin);
    }
    const hash = window.location.hash;
    const params = new URLSearchParams(hash.slice(1));
    setEmail(params.get("email") ?? "");
    // eslint-disable-next-line
  }, []);

  const changeLogin = (e) => {
    goLogin(e);
  };

  const changeTheme = (color) => {
    const metaThemeColor = document.querySelector("meta[name=theme-color]");
    metaThemeColor.setAttribute("content", color);
  };

  useEffect(() => {
    changeTheme("#f6f6f6");
    if (!notificationShown.current) {
      setHasNotification(true);
      const notificationKey = 'beta-notification';
      notification.warning({
        key: notificationKey,
        message: dictionary.beta.title,
        description: (
          <div>
            {dictionary.beta.description}{' '}
            <a href="https://dashboard.priamdigital.com" target="_blank" rel="noopener noreferrer">
              {dictionary.beta.liveUrl}
            </a>
          </div>
        ),
        duration: 0,
        placement: 'top',
        onClose: () => setHasNotification(false),
        className: 'beta-notification'
      });
      notificationShown.current = true;
    }
    return () => {
      changeTheme("#FFF");
    };
  }, [dictionary]);

  return (
    <div
      className={`container-box fadein sign ${hasNotification ? 'has-notification' : ''}`}
      style={{
        height: "100vh",
        width: "100vw",
        overflow: "auto",
      }}
    >
      <img src={Logo} alt="Priam" className="logo" />
      {!login ? (
        <Login
          email={email}
          setEmail={setEmail}
          changeLogin={changeLogin}
          externalError={externalError}
        />
      ) : (
        <SignUp
          email={email}
          setEmail={setEmail}
          changeLogin={changeLogin}
          externalError={externalError}
        />
      )}
    </div>
  );
};

export default Home;
