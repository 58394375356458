import React, { useContext, useState } from "react";
import { Row, Col, Upload, Checkbox, Popover, TimePicker } from "antd";
import { PlusOutlined, WarningOutlined } from "@ant-design/icons";
import { LanguageContext } from "../../../../../../context/Language";
import { AppContext } from "../../../../../../context/App";
import moment from "moment";
import Input from "../../../../../Form/Input";
import Radio from "../../../../../Form/Radio";
import Select from "../../../../../Form/Select";
import TrashIcon from "../../../../../Icon/TrashIcon";
import GenreSubGenreSelect from "../../../../../Form/GenreSubGenreSelect";
import AudioPlayer from "../../../../../AudioPlayer";
import { getAudioChannels, isAtmosAudio } from '../../../../../../utils/audioUtils';

const TrackInfoFirst = ({
  state,
  nameRef,
  audioRef,
  isrcRef,
  infoTrack,
  errorsTrack,
  changeHandler,
  handleSelect,
  handleChangeAudioUpload,
  openDelete,
  deleteSongModal,
  audio,
  audioFile,
  changeHandlerTime,
  permissionCheckboxChangeHandler,
  audioId,
}) => {
  const { dictionary, languagesList } = useContext(LanguageContext);
  const { trackTypeOptions } = useContext(AppContext);

  const [atmosError, setAtmosError] = useState(null);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8, color: "#8c8c8c" }}>{dictionary.upload}</div>
    </div>
  );

  // Called each render, i cant make it better :(
  const getPermission = (type) => {
    const permission =
      infoTrack?.permission?.filter((item) => item.type === type) || [];
    return permission.length && permission[0].enabled;
  };

  const AtmosWarning = () => (
    <div className="atmos-warning">
      <Popover 
        content={dictionary.atmosWarning}
        title={dictionary.atmosTrack}
        placement="top"
      >
        <WarningOutlined style={{ color: '#faad14', marginRight: '8px' }} />
        {dictionary.atmosTrack}
      </Popover>
    </div>
  );

  return (
    <React.Fragment>
      <Row className="gutter-row">
        <Col xs={24} sm={24} md={12} lg={12} className="col1">
          <Input
            refs={nameRef}
            name="title"
            required={true}
            value={infoTrack?.title || ""}
            onChange={(e) => changeHandler(e, "title")}
            label={dictionary.trackName}
            error={errorsTrack.trackNameErr}
            className={errorsTrack.trackNameErr ? "inputErr " : ""}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} className="col2">
          <Input
            name="version"
            onChange={(e) => changeHandler(e, "version")}
            value={infoTrack?.version || ""}
            label={dictionary.titleVersion}
            error={errorsTrack.trackVerErr}
            className={errorsTrack.trackVerErr ? "inputErr " : ""}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} className="col1">
          <Select
            refs={audioRef}
            required={true}
            showSearch={true}
            name="audio_lang"
            options={languagesList}
            error={errorsTrack.audioLangErr}
            value={infoTrack?.audio_lang || state.lang}
            placeholder={dictionary.select}
            label={dictionary.audioLanguage}
            onChange={(e) => handleSelect(e, "audio_lang")}
            className={
              (errorsTrack.audioLangErr ? "countErr " : "") +
              " selector-general"
            }
          />
          {!state.isNew ? (
            <Input
              refs={isrcRef}
              required={true}
              name="isrc"
              value={infoTrack?.isrc || ""}
              onChange={(e) => changeHandler(e, "isrc")}
              label={dictionary.isrc}
              error={errorsTrack.isrcErr}
              className={errorsTrack.isrcErr ? "inputErr " : ""}
            />
          ) : null}
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} className="col2">
        <GenreSubGenreSelect
            title={dictionary.genre}
            name="audio_genre"
            required={true}
            value={infoTrack.subgenres || []}
            error={errorsTrack.genreTrackErr || ""}
            onChange={(e) => handleSelect(e, "subgenres")}
          />
        </Col>
        <h2 style={{ width: "100%" }}>{dictionary.trackCreativeInfo}</h2>
        <Col xs={24} sm={24} md={12} lg={12} className="col1"> 
          <label>
            {dictionary.clipStartTime}
          </label>
          <TimePicker
            showNow={false}
            format={"mm:ss"}
            placeholder="00:00"
            name="clip_start_time"
            onChange={(_,e) => changeHandlerTime(_,e,"clip_start_time")}
            defaultValue={moment(infoTrack.clip_start_time || "00:00","mm:ss")}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} className="col2"> 
          <label>
            {dictionary.titleLength}
          </label>
          <TimePicker
            showNow={false}
            format={"mm:ss"}
            placeholder="00:00"
            name="title_length"
            onChange={(_,e) => changeHandlerTime(_,e,"title_length")}
            defaultValue={moment(infoTrack.title_length || "00:00","mm:ss")}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} className="col1"> 
          <label>
            {dictionary.clipLength}
          </label>
          <TimePicker
            showNow={false}
            format={"mm:ss"}
            placeholder="00:00"
            name="clip_length"
            onChange={(_,e) => changeHandlerTime(_,e,"clip_length")}
            defaultValue={moment(infoTrack.clip_length || "00:00","mm:ss")}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} className="col2"> 
          <label className="allWidth">{dictionary.trackPermission}</label>
          <Checkbox
            name={"track_sale"}
            checked={getPermission("track_sale")}
            className="check-terms"
            onChange={permissionCheckboxChangeHandler}
          >
            {dictionary.sale}
          </Checkbox>
          <Checkbox
            name={"stream"}
            checked={getPermission("stream")}
            className="check-terms"
            onChange={permissionCheckboxChangeHandler}
          >
            {dictionary.stream}
          </Checkbox>
          <Checkbox
            name={"download"}
            checked={getPermission("download")}
            className="check-terms"
            onChange={permissionCheckboxChangeHandler}
          >
            {dictionary.download}
          </Checkbox>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} className="col1">
          <Radio
            required={true}
            options={trackTypeOptions}
            onChange={(e) => changeHandler(e, "type")}
            value={infoTrack?.type || "original"}
            className="radio-options"
            label={dictionary.trackType}
          />
        </Col>
      </Row>
      <div className="audio">
        <h2 style={{ width: "100%" }}>{dictionary.audio}*</h2>
        {audioFile || infoTrack?.dataFromAudioURI ? (
          <TrashIcon onClick={openDelete} />
        ) : null}
        {deleteSongModal()}
      </div>
      {audio ? (
        atmosError ? (
          <AtmosWarning />
        ) : (
          <AudioPlayer
            title=""
            dataFromURI={infoTrack?.dataFromAudioURI}
            dataFromFile={audioFile}
            audioId={audioId}
          />
        )
      ) : (
        <div>
          <Upload
            customRequest={() => {}}
            name="avatar"
            accept="audio/x-wav, audio/wav, audio/x-flac, audio/flac"
            showUploadList={false}
            onChange={async (info) => {
              const file = info.file.originFileObj;
              if (file) {
                const channels = await getAudioChannels(file);
                setAtmosError(isAtmosAudio(channels) ? dictionary.atmosWarning : null);
                handleChangeAudioUpload(info);
              }
            }}
            listType="picture-card"
            className={(errorsTrack.fileErr ? "imageErr " : "") + " avatar-uploader"}
            maxCount={1}
          >
            {uploadButton}
          </Upload>
          <div className="cover-desc">{dictionary.audioDesc}</div>
          {errorsTrack.fileErr && (
            <div className="upload-text-error">{errorsTrack.fileErr}</div>
          )}
          {atmosError && <AtmosWarning />}
        </div>
      )}
    </React.Fragment>
  );
};

export default TrackInfoFirst;
