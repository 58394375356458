import React, { useState, useEffect, useContext } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Badge, Popconfirm } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../../../../context/User";
import Avatar from "react-avatar";
import AlbumIcon from "../../../../Icon/AlbumIcon/AlbumIcon.svg";
import RejectionTimeLine from "../../../../TimeLine/RejectionTimeLine";

const ReleaseList = ({
  img,
  title,
  artist,
  myClass,
  section,
  idRelease,
  released,
  imgResized,
  changeItem,
  showDeleteDraft,
  rejections,
  canDelete = false,
}) => {
  const { permission, type } = useContext(UserContext);
  const [drawedImage, setDreawedImage] = useState(null);
  const [link, setLink] = useState("/");
  const navigate = useNavigate();

  useEffect(() => {
    setImage();
    defineLink();
    // eslint-disable-next-line
  }, [img]);

  const defineLink = () => {
    if (type === "admin") {
      if (!released) {
        if (section === "draft") {
          setLink("/release/view?r=" + idRelease + "&edit=true");
        } else {
          setLink("/release/view?r=" + idRelease);
        }
      } else {
        setLink("/release/view?r=" + idRelease);
      }
    } else {
      setLink("/newRelease?r=" + idRelease);
    }
  };

  const previewImg = (e, el) => {
    let imgList = document.getElementById("imgList" + el);
    if (imgList) {
      imgList.src = img;
      let image = document.createElement("img");
      image.src = img;
      image.addEventListener(
        "error",
        () => {
          var isLoaded = image.complete && image.naturalHeight !== 0;
          if (!isLoaded) {
            imgList.src = AlbumIcon;
          }
        },
        false
      );
    }
    return;
  };

  const setImage = () => {
    const imgSrcBucket =
      // eslint-disable-next-line no-undef
      process.env.REACT_APP_S3_BUCKET_CDN_URL || process.env.REACT_APP_STORAGE;

    if (img) {
      setDreawedImage(
        <div className="image">
          <img
            src={imgSrcBucket + imgResized}
            alt={title}
            id={"imgList" + idRelease}
            onError={(e) => previewImg(e, idRelease)}
          />
        </div>
      );
    } else {
      setDreawedImage(<Avatar name={title} round="10px" className="avatar-list" />);
    }
  };
  return (
    <li
      className={myClass + " cd-data new-artist fadein"}
      onClick={() => changeItem("/release")}
    >
      <div className="dash-badget">
        <Popconfirm
          title={<RejectionTimeLine rejections={rejections} />}
          showCancel={false}
          icon={false}
          okText={"ok"}
        >
          <Badge count={rejections.length}></Badge>
        </Popconfirm>
      </div>

      {canDelete ? (
        <div className="menu-cd">
          <EditOutlined onClick={() => navigate(link)} />
          <DeleteOutlined onClick={() => showDeleteDraft(idRelease, title)} />
        </div>
      ) : null}
      {permission?.release?.update ? (
        <Link to={link}>
          {drawedImage}
          <p className="album">{title}</p>
          <p className="artist">{artist}</p>
        </Link>
      ) : (
        <div>
          {drawedImage}
          <p className="album">{title}</p>
          <p className="artist">{artist}</p>
        </div>
      )}
    </li>
  );
};

export default ReleaseList;
