import React from "react";
import { Timeline } from "antd";
import moment from "moment";

const RejectionTimeLine = ({ rejections }) => {
  const items = [];
  for (const rejection of rejections) {
    items.push(
      <Timeline.Item>
        {rejection.reason ? (
          <>
            {rejection.reason}
            <br />
          </>
        ) : null}

        <small>{moment(rejection.date).format("D MMM, YYYY")}</small>
      </Timeline.Item>
    );
  }

  return items.length > 0 ? (
    <React.Fragment>
      <div className="overflowed">
        <Timeline>{items}</Timeline>
      </div>
    </React.Fragment>
  ) : null;
};

export default RejectionTimeLine;
