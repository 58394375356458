import React, { useContext, useState } from "react";
import { Row, Col } from "antd";
import { LanguageContext } from "../../../context/Language";
import "../../../containers/Register/register.css";
import Button from "../../Buttons/GeneralButton";
import Visitor from "../../../classes/visitor/visitor";
import Input from "../../Form/Input";
import { Link } from "react-router-dom";
import { notification } from "antd";

const Code = ({ state, setKeys, setInput, setValidated }) => {
  const { dictionary, locale } = useContext(LanguageContext);
  const [error, setError] = useState(null);
  const [code, setCode] = useState("");

  const onChange = (e) => {
    setCode(e.target.value);
  };

  const validate = async () => {
    setError(null);
    if (!code) {
      setError(dictionary.emptyField);
      return;
    }
    const verified = await Visitor.verify_code(state.email, code);
    if (verified?.result) {
      setInput({
        ...state,
        verified: true,
      });
      document.getElementById('root').scrollTo({
        top: 0,
        behavior: 'smooth'
      });

      setKeys({
        ...state,
        verified: true,
      });

      setValidated(true);
    } else if (verified?.message) {
      setError(dictionary[verified.message]);
    } else {
        setError(dictionary.wrongCode);
    }
  };

  const resend = async () => {
    const language = locale.includes("es") ? "es" : "en";
    const result = await Visitor.request_code(state.email, language);
    if (result.status === 200) {
      notification.success({
        message: dictionary.verifyRequestSuccess,
        placement: "bottomRight",
      });
    } else {
      notification.error({
        message: dictionary.verifyRequestError,
        placement: "bottomRight",
      });
    }
  };

  return (
    <div className="terms">
      <h3>
        {dictionary.weHaveSent} <br />
        {dictionary.enterVerificationCode.replace("--email--", state.email)}:
      </h3>
      <div className="forms form-verify">
        <Row className="gutter-row">
          <Col xs={24} sm={24} md={12} lg={12}>
            <Input
              name="code"
              required={true}
              value={code}
              maxLength={6}
              onChange={onChange}
              label={dictionary.verificationCode}
              className={error ? "inputErrBorder centered-input" : "centered-input"}
            />
            {error ? <div className="text-err">{error}</div> : null}
            <p className="sub-message">{dictionary.notReceivedcode}</p>
            <p>
              <Link to="#" className="plain-link" onClick={resend}>
                {dictionary.resendVerificatioCode}
              </Link>
            </p>
          </Col>
        </Row>
      </div>
      <div className="align-right reg-btn">
        <Button text={dictionary.validate} size="md" onClick={validate} />
      </div>
    </div>
  );
};

export default Code;
