import React, { useContext, useEffect, useMemo } from "react";
import { Select } from "antd";
import { UserContext } from "../../../context/User";
import { LanguageContext } from "../../../context/Language";
import "../form.css";

const GenreSubGenreSelectInput = (props) => {
  const { value = [] } = props;
  const { dictionary } = useContext(LanguageContext);
  const { subGenre, subGenreObjects } = useContext(UserContext);

  useEffect(() => {}, [value]);

  const optionsMemo = useMemo(() => {
    
    return subGenre.map(({ genre, subgenres }) => ({
      label: genre.name,
      options: [
        {
          label: genre.name,
          value: JSON.stringify({ is_genre: true, _id: genre._id }),
        },
        ...subgenres.map((subgenre) => ({
          label: subgenre.name,
          value: JSON.stringify({
            is_genre: false,
            _id: subgenre._id,
            genre_id: genre._id,
          }),
        })),
      ],
    }));
  }, [subGenre]);

  const valueMemo = useMemo(() => {
    let result = [];
    if (Array.isArray(value) > 0 && value.length > 0) {
      // this exists because in some point we are sending the value
      // like an array of object_ids
      if (props.value[0] && props.value[0].length < 30) {
        result = props.value
          .filter((val) => subGenreObjects[val])
          .map((val) => subGenreObjects[val]);
      } else {
        result = props.value;
      }
    }
    return result;
  }, [props.value, subGenreObjects]);

  return (
    <div className="relative-input">
      {props.title && (
        <label className="allWidth">
          {props.title}
          <span>{props.required ? "*" : ""}</span>
        </label>
      )}
      <Select
        {...props}
        allowClear
        mode="multiple"
        defaultValue={valueMemo}
        style={{ width: "100%" }}
        optionFilterProp={"label"}
        placeholder={dictionary.select}
        options={optionsMemo}
        className={
          (props.error ? "countErr " : "") + " selector-general multiselect"
        }
      />
      {props.error ? (
        <div className="multitext-error">{props.error}</div>
      ) : null}
    </div>
  );
};

export default GenreSubGenreSelectInput;
