import React, { useEffect, useState, useReducer, useContext } from "react";
import { notification, Button } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { ArtistContext } from "../../../context/Artist";
import { LanguageContext } from "../../../context/Language";
import { UploadServiceContext } from "../../../context/Services";
import AdminCalls from "../../../classes/admin/admin";
import ReleaseCalls from "../../../classes/release/release";
import MarkIcon from "../../../components/Icon/MarkIcon";
import Tracks from "../../../components/Release/Detail/Tracks";
import Header from "../../../components/Release/Detail/Header";
import Actions from "../../../components/Release/Detail/Actions";
import Delivery from "../../../components/Release/Detail/Delivery";
import Territory from "../../../components/Release/Detail/Territory";
import Copyright from "../../../components/Release/Detail/Copyright";
import RequestedStores from "../../../components/Release/Detail/RequestedStores";
import GeneralInformation from "../../../components/Release/Detail/GeneralInformation";
import ReviewNoteModal from "../../../components/Release/Detail/Modals/ReviewNoteModal";
import AddUpcIsrcModal from "../../../components/Release/Detail/Modals/AddUpcIsrcModal";
import { releaseDetailReducer } from "../../../util";
import "./ReleaseDetail.css";

const cleanContributorBeforeSend = (contributorArray) =>
  contributorArray.map((contributor) => {
    delete contributor.origin;
    delete contributor._id;
    return contributor;
  });

const ReleaseDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const editParam = searchParams.get("edit");
  const relId = searchParams.get("r");

  const [releaseDataChanged, releaseDataChangedDispatcher] = useReducer(
    releaseDetailReducer,
    {}
  );

  const { updateArtistsByLabelId } = useContext(ArtistContext);
  const { addFiles } = useContext(UploadServiceContext);
  const { dictionary } = useContext(LanguageContext);

  // Come from the ?edit=true param, show the edit buttons on subcomponents
  const [isEditMode, setIsEditMode] = useState(!!editParam);
  const [enableEdit, setEnableEdit] = useState(isEditMode);

  const [isLoading, setIsLoading] = useState(true);
  const [releaseData, setReleaseData] = useState({});
  const [tracks, setTracks] = useState([]);
  const [updateUpc, isUpdateUpc] = useState(false);
  const [showReviewNoteModal, setShowReviewNoteModal] = useState(false);
  const [showAddUpcIsrcModal, setShowAddUpcIsrcModal] = useState(false);
  const [releaseId, setReleaseId] = useState(relId);

  // This is only needed for refresh situations
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const editParam = searchParams.get("edit");
    const relId = searchParams.get("r");

    if (!relId) return;

    setIsEditMode(!!editParam);
    loadRelease(relId);
  }, [location.search]);

  const loadRelease = (releaseId) => {
    setIsLoading(true);
    setReleaseId(releaseId);

    ReleaseCalls.getReleaseDetails(releaseId)
      .then((data) => {
        setReleaseData(data.release);
        setTracks(data.tracks);
        updateArtistsByLabelId(data.release.label_id);
      })
      .catch((error) => {
        console.log(error);
        setTracks([]);
        setReleaseData({});
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const toggleEditMode = () => {
    setEnableEdit(!enableEdit);
  };

  /**
   * Release and track save handler
   * This function take the data from the reducer and separate it into release, files and tracks
   * first the release data if exists any change
   * then the tracks data if exists any change
   * and finally the files through the addFiles function from the UploadServiceContext
   * the data and the files are served as a promise to be resolved
   * */
  const sendDataToUpdate = () => {
    if (!releaseId) return;

    setIsLoading(true);

    const promises = [];
    const releaseToSend = { ...releaseDataChanged };
    const filesToSend = [];
    const tracksChangedToSend = [];
    const newTracks = [];

    // If the cover is changed, we remove it and send it after the release
    if (Object.hasOwnProperty.call(releaseDataChanged, "cover")) {
      filesToSend.push({
        type: "cover",
        releaseId,
        file: releaseDataChanged.cover,
      });
      delete releaseToSend.cover;
    }

    // List the tracks and remove the files from the track object
    if (Object.hasOwnProperty.call(releaseDataChanged, "tracks")) {
      for (const track of releaseDataChanged.tracks) {
        const isNew = track._id.includes("new");
        const hasFiles = Object.hasOwnProperty.call(track, "newFiles");
        const trackToPush = { ...track, release_id: releaseId };
        //Maybe its not necessary evaluate the isNew, the clean function can handle it

        if (Object.hasOwnProperty.call(trackToPush, "artists"))
          trackToPush.artists = cleanContributorBeforeSend(trackToPush.artists);

        if (Object.hasOwnProperty.call(trackToPush, "writers"))
          trackToPush.writers = cleanContributorBeforeSend(trackToPush.writers);

        if (Object.hasOwnProperty.call(trackToPush, "producers"))
          trackToPush.producers = cleanContributorBeforeSend(
            trackToPush.producers
          );

        if (hasFiles && !isNew) {
          filesToSend.push({
            type: "track",
            releaseId,
            file: track.newFiles?.fileList[0]?.originFileObj,
            trackId: track._id,
          });

          // remove the newFiles for pass the track to the server
          delete trackToPush.newFiles;
        }

        if (isNew) {
          newTracks.push({
            file: {
              type: "track",
              releaseId,
              file: track.newFiles?.fileList[0]?.originFileObj,
              trackId: track._id,
            },
            track: trackToPush,
          });
          delete trackToPush.newFiles;
        } else tracksChangedToSend.push(trackToPush);
      }
      // remove tracks for pass the release to the server
      delete releaseToSend.tracks;
    }

    if (Object.hasOwnProperty.call(releaseToSend, "artists"))
      releaseToSend.artists = cleanContributorBeforeSend(releaseToSend.artists);

    if (Object.hasOwnProperty.call(releaseToSend, "writers"))
      releaseToSend.writers = cleanContributorBeforeSend(releaseToSend.writers);

    if (Object.hasOwnProperty.call(releaseToSend, "producers"))
      releaseToSend.producers = cleanContributorBeforeSend(
        releaseToSend.producers
      );

    if (Object.keys(releaseToSend).length > 0)
      promises.push(AdminCalls.releaseUpdate(releaseId, releaseToSend));

    if (tracksChangedToSend.length > 0) {
      for (const ts of tracksChangedToSend) {
        const tid = ts._id;
        delete ts._id;

        if (tid.includes("delete"))
          promises.push(AdminCalls.trackDelete(tid.replace("delete", "")));

        if (!tid.includes("new") && !tid.includes("delete"))
          promises.push(AdminCalls.trackUpdate(tid, ts));
      }
    }

    if (newTracks.length > 0) {
      for (const nt of newTracks) {
        const { file, track } = nt;
        delete track._id;
        promises.push(
          AdminCalls.trackCreate(track)
            .then((response) => {
              if (!response?.error) {
                file.trackId = response.track;
                addFiles([file]);
              }
            })
            .catch((error) => {
              console.error("error", error);
              notification.error({
                key: "releaseDetailNotification",
                placement: "bottomRight",
                message: dictionary.errorGeneral,
                duration: 5,
              });
            })
        );
      }
    }

    /*
    console.log("filesToSend", filesToSend);
    console.log("tracksToSend", tracksToSend);
    console.log("releaseToSend", releaseToSend);
    */

    addFiles(filesToSend);

    Promise.all(promises)
      .then(() => {
        notification.success({
          key: "releaseDetailNotification",
          placement: "bottomRight",
          message: dictionary.successGeneral,
          duration: 5,
        });
      })
      .catch((error) => {
        console.error(error);
        notification.error({
          key: "releaseDetailNotification",
          placement: "bottomRight",
          message: dictionary.errorGeneral,
          duration: 5,
        });
      })
      .finally(() => {
        releaseDataChangedDispatcher({
          type: "cleanState",
          payload: {},
        });
        setReleaseData({});
        setTracks([]);
        loadRelease(releaseId);
      });
  };

  return (
    <div id="release-detail-container">
      {isEditMode ? (
        <div className="edit_button_container">
          <Button
            className={"edit_button" + (enableEdit ? " active" : "")}
            shape="circle"
            onClick={() => toggleEditMode()}
          >
            {dictionary.edit} <MarkIcon />
          </Button>
        </div>
      ) : null}
      <Header
        releaseId={releaseId}
        dispatch={releaseDataChangedDispatcher}
        dataToUpdate={releaseDataChanged}
        isLoading={isLoading}
        isEditMode={isEditMode}
        isUpdateUpc={isUpdateUpc}
        enableEdit={enableEdit}
        loadRelease={() => loadRelease(releaseId)}
        setShowAddUpcIsrcModal={() => setShowAddUpcIsrcModal(true)}
        {...releaseData}
      />
      <GeneralInformation
        releaseId={releaseId}
        enableEdit={enableEdit}
        dispatch={releaseDataChangedDispatcher}
        dataToUpdate={releaseDataChanged}
        isLoading={isLoading}
        {...releaseData}
      />
      <Tracks
        dispatch={releaseDataChangedDispatcher}
        dataToUpdate={releaseDataChanged.tracks}
        tracks={tracks}
        setTracks={setTracks}
        labelId={releaseData.label_id}
        isEditMode={isEditMode}
        isLoading={isLoading}
        releaseData={releaseData}
        setReleaseData={setReleaseData}
        setShowAddUpcIsrcModal={() => setShowAddUpcIsrcModal(true)}
      />
      <Territory
        dispatch={releaseDataChangedDispatcher}
        dataToUpdate={releaseDataChanged}
        isEditMode={isEditMode}
        enableEdit={enableEdit}
        isLoading={isLoading}
        territory={releaseData.territory}
      />
      {/*TODO: RequestedStores has a performance issue, 
      make the state dispatcher run slow*/}
      <RequestedStores
        isEditMode={isEditMode}
        isLoading={isLoading}
        releaseId={releaseId}
        releaseAudioSaladIds={
          releaseData?.audiosalad_id ? [releaseData?.audiosalad_id] : []
        }
        releaseDate={releaseData.date}
        releaseDsp={releaseData.dsp_release}
      />
      <Delivery
        dispatch={releaseDataChangedDispatcher}
        dataToUpdate={releaseDataChanged}
        isLoading={isLoading}
        tracks={tracks}
        priceTier={releaseData.price_tier}
        enableEdit={enableEdit}
      />
      <Copyright
        dispatch={releaseDataChangedDispatcher}
        dataToUpdate={releaseDataChanged}
        isLoading={isLoading}
        data={releaseData}
        enableEdit={enableEdit}
      />
      {/* This can be fixed on the bottom screen, disabled until there is a change*/}
      <Actions
        sendDataToUpdate={() => sendDataToUpdate()}
        setShowReviewNoteModal={() => setShowReviewNoteModal(true)}
        isEditMode={isEditMode}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        loadRelease={() => loadRelease(releaseId)}
        dataToUpdate={releaseDataChanged}
        {...releaseData}
      />
      <ReviewNoteModal
        releaseId={releaseId}
        isModalVisible={showReviewNoteModal}
        releaseTitle={releaseData.title}
        reviewNotes={releaseData.rejections}
        onCancel={() => setShowReviewNoteModal(false)}
        onConfirm={() => navigate("/release")}
      />
      <AddUpcIsrcModal
        isModalVisible={showAddUpcIsrcModal}
        onCancel={() => {
          setShowAddUpcIsrcModal(false);
          isUpdateUpc(false);
        }}
        onConfirm={() => setShowAddUpcIsrcModal(false)}
        assignUpc={true}
        releaseId={releaseId}
        updateUpc={updateUpc}
        isUpdateUpc={isUpdateUpc}
        loadRelease={() => loadRelease(releaseId)}
      />
    </div>
  );
};
export default ReleaseDetail;
