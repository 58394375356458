import BaseCalls from "../calls";

export default class Track extends BaseCalls {
  static track_object() {
    return {
      release_id: null,
      title: "",
      number: 0,
      type: "original",
      audio_lang: 0,
      subgenres: [],
      artists: [],
      writers: [],
      producers: [],
      // TODO remove participants from flow, only needed in front
      participants: [],
      version: "",
      lyrics: "",
      length: 0,
      // s3_url: "",
      asset: [],
      copyright: {
        data: "",
        year: new Date().getFullYear(),
      },
      phonographic: {
        data: "",
        year: new Date().getFullYear(),
      },
      rights_holder: "",
      advocacy: "None",
      // isrc: "",
      permission: [
        {
          type: "track_sale",
          enabled: true,
          country: ["WW"],
        },
        {
          type: "stream",
          enabled: true,
          country: ["WW"],
        },
        {
          type: "download",
          enabled: true,
          country: ["WW"],
        },
      ],
      clip_start_time: "",
      title_length: "",
      clip_length: "",
      /*preview_start: 0,
            categories: "",
            preview_portion: 30,
            */
      // completeInfo: false,
    };
  }

  constructor() {
    super();
    this.track = {
      release_id: null,
      title: "",
      number: 0, // not uncommented, because it's not used: just for guide
      type: "original",
      audio_lang: 0,
      subgenres: [],
      artists: [],
      writers: [],
      producers: [],
      // TODO remove participants from flow, only needed in front
      participants: [],
      version: "",
      lyrics: "",
      length: 0,
      // s3_url: "",
      asset: [],
      copyright: {
        data: "",
        year: new Date().getFullYear(),
      },
      phonographic: {
        data: "",
        year: new Date().getFullYear(),
      },
      rights_holder: "",
      advocacy: "None",
      // isrc: "",
      permission: [
        {
          type: "track_sale",
          enabled: true,
          country: ["WW"],
        },
        {
          type: "stream",
          enabled: true,
          country: ["WW"],
        },
        {
          type: "download",
          enabled: true,
          country: ["WW"],
        },
      ],
      clip_start_time: "",
      title_length: "",
      clip_length: "",
    };
    this.trackBeforeEdit = {};
    this.tracks = [];
  }

  /*
   *   Functiont to insert tracks on state from
   *
   */
  insertTrackState(track) {
    this.tracks = track;
  }

  /*
   *   Function to change time on seconds
   *   @param time in seconds
   */
  timeToSeconds(time) {
    let timeArray = time.split(":");
    let seconds = 0;
    seconds += parseInt(timeArray[0]) * 60;
    seconds += parseInt(timeArray[1]);
    return seconds;
  }

  deleteTrack(index, forceDelete = false) {
    // verify if the track has some information
    // if not maybe was a mistake from user
    const missingInfo = this.trackValidation(this.tracks[index]);
    if (forceDelete || missingInfo.length === Object.keys(this.track).length) {
      if (this.trackBeforeEdit.number)
        this.tracks[index] = this.trackBeforeEdit;
      else this.tracks.splice(index, 1);
    }
    return this.tracks;
  }

  reOrderTracks(tracks = null) {
    let tracksArray = tracks || this.tracks;
    let newTracks = [];
    for (let i = 0; i < tracksArray.length; i++) {
      newTracks[i] = tracksArray[i];
      newTracks[i].number = i + 1;
    }
    return newTracks;
  }

  deleteTrackFromState(index) {
    if (this.tracks.length === 1) {
      this.tracks = [];
    } else {
      this.tracks.splice(index, 1);
    }
  }

  trackValidation(track) {
    let errors = [];
    for (let key in this.track) {
      if (
        !track[key] ||
        (track[key] && Array.isArray(track[key]) && !track[key].length)
      ) {
        errors.push({
          key,
          value: track[key],
          message: `${key} is missing`,
        });
      }
    }
    return errors;

    // missing on Frontend design
    /* if(!track.lyrics) {
            errors.push("Lyrics is required");
        }
        if(!track.preview_start) {
            errors.push("Preview start is required");
        }
        if(!track.preview_portion) {
            errors.push("Preview portion is required");
        }
        if(!track.copyright.data) {
            errors.push("Copyright is required");
        }
        if(!track.phonographic.data) {
            errors.push("Phonographic is required");
        }
        if(!track.rights_holder) {
            errors.push("Rights holder is required");
        }
        if(!track.permission.length) {
            errors.push("Permission is required");
        }
        if(!track.advocacy) {
            errors.push("Advocacy is required");
        }
        */
  }

  /*
   *   Function to set completeInfo if the track is complete
   */
  setCompleteInfo(index) {
    const errors = this.trackValidation(this.tracks[index]);
    if (errors.length === 0) {
      this.tracks[index].completeInfo = true;
    }
    return this.tracks;
  }

  /**
   *   Function to set the file s3 url and assets on state
   * @param index of the track
   * @param fileS3Url s3 url
   * @param props of the file {type, format, checksum, filename}
   */
  setFileS3Url(index, fileS3Url, props = {}) {
    this.tracks[index].s3_url = fileS3Url;
    const { type, format, checksum, filename } = props;
    this.tracks[index].asset = [
      {
        type,
        format,
        checksum,
        filename,
      },
    ];
    return this.tracks;
  }
}
