import React from "react";
import { Modal } from "antd";
import "./style.css";

export default function DefaultModal(props) {
  const { 
    body, 
    title, 
    footer, 
    handleCancel, 
    isModalVisible,
    closeable = false,
    closeIcon = false,
    addclassName = null,
  } = props;

  return (
    <Modal
      open={isModalVisible}
      onCancel={handleCancel}
      className={"modal-box " + addclassName}
      closeIcon={closeIcon || false}
      closable={closeable}
      title={<div className="box-header">{title}</div>}
      footer={footer}
      {...props}
    >
      {body}
    </Modal>
  );
}
