import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import "antd/dist/antd.min.css";
import { UserContext } from "./context/User";
import { LanguageContext } from "./context/Language";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import SecureLS from "secure-ls";
import UPC from "./containers/UPC";
import Dsp from "./containers/DSP";
import Home from "./containers/Home";
import Label from "./containers/Label";
import Users from "./containers/Users";
import Loader from "./components/Loader";
import Reload from "./containers/Reload";
import Logout from "./containers/Logout";
import Thanks from "./containers/Thanks";
import Unpaid from "./containers/Unpaid";
import Artist from "./containers/Artist";
import Layout from "./containers/Layout";
import Review from "./containers/Review";
import Pricing from "./containers/Pricing";
import Releases from "./containers/Releases";
import Settings from "./containers/Settings";
import Register from "./containers/Register";
import ResetPwd from "./containers/ResetPwd";
import Dashboard from "./containers/Dashboard";
import Contracts from "./containers/Contracts";
import Royalties from "./containers/Royalties";
import MailVerify from "./containers/MailVerify";
import NewRelease from "./containers/NewRelease";
import SignupError from "./containers/SignupError";
import VerifiedEmail from "./containers/VerifiedEmail";
import Distribution from "./containers/DistributionQueue/";
import UserDetail from "./containers/Users/detail/UserDetail";
import ReleaseDetail from "./containers/Releases/detail/ReleaseDetail";
import CopyrightVerification from "./containers/Releases/Verification/CopyrightVerification";
import CreateContract from "./containers/CreateContract";
import ContractDetail from "./containers/Contracts/ContractDetail/ContractDetail";
const ls = new SecureLS({ encodingType: "base64", isCompression: false });

const App = () => {
  const {
    sessionAuth,
    status,
    authLoading,
    register,
    emailVerified,
    permission,
    type,
  } = useContext(UserContext);
  const { dictionary } = useContext(LanguageContext);
  const [current, setCurrent] = useState(window.location.pathname);
  const [open, setOpen] = useState(false);

  const changeItem = (e) => {
    setCurrent(e.key || e);
    setOpen(false);
  };

  const showDrawer = () => {
    setOpen(true);
  };

  /* Create a useEffect to change the title of the page for each route */
  useEffect(() => {
    let title = "Dashboard | Priam Digital";
    if (current === "/") {
      if (register) {
        title = "Register | Priam Digital";
      } else if (!sessionAuth) {
        title = "Priam Digital";
      }
      document.title = title;
      return;
    }
    let path = current.split("/")[1].toLocaleLowerCase();
    switch (path) {
      case "release":
        path = dictionary.path.release;
        break;
      case "artist":
        path = dictionary.artists;
        break;
      case "label":
        path = dictionary.labels;
        break;
      case "distributionqueue":
        path = dictionary.distribution;
        break;
      case "newrelease":
        path = dictionary.path.newRelease;
        break;
      case "settings":
        path = dictionary.settings;
        break;
      case "user":
        path = dictionary.users;
        break;
      case "review":
        path = dictionary.review;
        break;
      case "contracts":
        path = dictionary.contracts;
        break;
      case "royalties":
        path = dictionary.royalties;
        break;
      case "upc":
        path = "UPC & ISRC";
        break;
      default:
        break;
    }
    title = path + " | Priam Digital";
    document.title = title.charAt(0).toUpperCase() + title.slice(1);
  }, [sessionAuth, current, register]);

  const nonAuthRoutes = () => {
    return (
      <Routes>
        <Route exact path="/" element={register ? <Register /> : <Home />} />
        <Route
          exact
          path="/register"
          element={register ? <Register /> : <Home />}
        />
        <Route
          exact
          path="/signupError"
          element={register ? <SignupError /> : <Home />}
        />
        <Route
          exact
          path="/verifiedEmail"
          element={register ? <Register /> : <VerifiedEmail />}
        />
        <Route
          exact
          path="/password/recovery"
          element={<ResetPwd />}
        />
        <Route path="*" element={register ? <Register /> : <Home />} />
      </Routes>
    );
  };

  const authRoutes = () => {
    if (status === "unpaid") {
      return (
        <Routes>
          <Route path="/unpaid" element={<Unpaid />} />
          <Route path="*" element={<Navigate to="/unpaid" replace={true} />} />
        </Routes>
      );
    }

    if (!emailVerified) {
      return (
        <Routes>
          <Route path="/settings" element={<Settings page="1" />} />
          <Route path="/settings#membership" element={<Settings />} />
          <Route path="/settings#password" element={<Settings />} />
          <Route path="*" element={<MailVerify />} />
        </Routes>
      );
    }

    const routes = [
      { path: "/", element: <Dashboard changeItem={changeItem} /> },
      { path: "/newRelease", element: <NewRelease /> },
      { path: "/verifiedEmail", element: <VerifiedEmail /> },
      { path: "/thanks", element: <Thanks /> },
      { path: "/artist", element: <Artist /> },
      { path: "/release", element: <Releases /> },
      { path: "/settings", element: <Settings /> },
      { path: "/tiers", element: <Pricing /> },
      { path: "/signupError", element: <Logout /> },
      { path: "*", element: <Reload /> },
    ];

    if (type === "admin") {
      routes.push({ path: "/dsp", element: <Dsp /> });
      routes.push({ path: "/review", element: <Review /> });
      routes.push({ path: "/distributionQueue", element: <Distribution /> });
      routes.push({ path: "/user", element: <Users /> });
      routes.push({ path: "/release/view", element: <ReleaseDetail /> });
      routes.push({ path: "/release/verification", element: <CopyrightVerification /> });
      routes.push({ path: "/user/view", element: <UserDetail /> });
      routes.push({ path: "/contracts", element: <Contracts /> });
      routes.push({ path: "/contracts/create", element: <CreateContract /> });
      routes.push({ path: "/contracts/update", element: <CreateContract />,});
      routes.push({ path: "/contracts/label", element: <ContractDetail />, });
    }
    if (type !== "artist") {
      routes.push({ path: "/label", element: <Label /> });
    }
    if (type !== "admin") {
      routes.push({ path: "/royalties", element: <Royalties /> });
    }
    if(ls.get("inviteToken") && window.location.pathname === "/register"){
      routes.push({ path: "/register", element: <Logout /> });
    } else {
      routes.push({ path: "/register", element: <Dashboard /> });
    }
    // We need to check with Argent if any user with the permission can see this
    // or only the admin type
    if (permission && permission.upc && permission.upc.see)
      routes.push({ path: "/upc", element: <UPC /> });

    return (
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
      </Routes>
    );
  };

  return (
    <Router>
      {authLoading ? (
        <Loader />
      ) : sessionAuth ? (
        <Layout
          open={open}
          current={current}
          setOpen={setOpen}
          changeItem={changeItem}
          showDrawer={showDrawer}
        >
          {authRoutes()}
        </Layout>
      ) : register ? (
        <Layout noSession={true}>{nonAuthRoutes()}</Layout>
      ) : (
        nonAuthRoutes()
      )}
    </Router>
  );
};

export default App;
